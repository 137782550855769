function resaltarPestañaMenu(id){
    id=id.replace('/','\\/');
	$(".nav-item").removeClass("active");
    $("#"+id).addClass("active");
    $("#"+id+"2").addClass("active");
}

function desplegarSubMenu(id){
    id=id.replace('/','\\/');
    $("#"+id+" .submenu").addClass("show");
}

function ocultarSubMenus(){
    $(".submenu").removeClass("show");
}

function enfocarElemento(id_elemento){
    setTimeout(()=>{
        $("#"+id_elemento).focus();
    },0)
}

function clickearElemento(id_elemento) {
    setTimeout(function() {
        $('#'+id_elemento).trigger('click');
    }, 0);
}